/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />
/// <reference path='../../../../../node_modules/@types/bootstrap/index.d.ts' />

$(document).ready(() => {
    ShoppingCartWidget.initialize();
    $('.mini-cart-carousel').carousel({ interval: 0 });
});

class ShoppingCartWidget {
    public static initialize() {
        this.refreshCartWidget();
        $(document).on('cider.shoppingCart.cartUpdated',
            (_e: Event, updatedMiniCartContentHtml: string) => {
                if (updatedMiniCartContentHtml == null || updatedMiniCartContentHtml.trim().length === 0) {
                    this.refreshCartWidget();
                } else {
                    this.setCartContent(updatedMiniCartContentHtml);
                }
            });

        this.bindShoppingCartButton($('.shopping-cart-button'));
        this.bindCheckoutButton($('.checkout-button'));
    }

    public static refreshCartWidget() {
        // Add timestamp to the url to prevent Chrome caching
        let cartWidget = $('.shopping-cart-button:first');
        if (cartWidget.length > 0 && cartWidget.data('get-cart-url') != undefined) {
            let url = cartWidget.data('get-cart-url') + '/' + new Date().getTime();

            $.get(url, miniCartContentHtml => {
                this.setCartContent(miniCartContentHtml);
            });
        }
    }

    public static setCartContent(html: string) {
        let miniCartContent = $('.shopping-cart-button.dropdown');
        miniCartContent.html(html);

        this.bindShoppingCartButton(miniCartContent.find('.shopping-cart-button'));
        this.bindCheckoutButton(miniCartContent.find('.checkout-button'));
    }

    private static bindShoppingCartButton(shoppingCartButton: JQuery) {
        let mousedOver = false;
        shoppingCartButton.on('added-to-cart', () => {
            shoppingCartButton.addClass('open');
            mousedOver = false;
            setInterval(() => {
                if (!mousedOver) {
                    shoppingCartButton.removeClass('open');
                    shoppingCartButton.children('.dropdown-bar').removeClass('open');
                }
            }, 5000);
        });

        shoppingCartButton.on('mouseenter mouseover mouseleave', () => {
            mousedOver = true;
        });
    }

    private static bindCheckoutButton(checkoutButton: JQuery) {
        checkoutButton.click(event => {
            if (event.which === 1) {
                // This behavior should only happen with a left click.  
                $('.mini-cart .spinner').addClass('csspinner ringed');
                $('.shopping-cart-button').addClass('leave-open');
                $('.mini-cart-area').addClass('leave-open');
            }
        });
    }
}
